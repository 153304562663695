<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img">
        </b-navbar>
        <b-container class="content-section">
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="col-md-12 mt-2">
                <form v-on:submit.prevent="onSubmit">
                    <div class="form-group ">
                        <input type="text"  @blur="validationRule()"
                            class="form-control" v-model="profileRequest.name" id="inputName" placeholder="အမည် ဖြည့်ပါ">
                        <small class="text-danger">{{validationData.name}}</small>
                    </div>
                    <div class="form-group ">
                        <input type="text" 
                            class="form-control" v-model="profileRequest.phone" id="inputPhone" placeholder="ဖုန်းနံပါတ် ဖြည့်ပါ" disabled>
                    </div>
                    <div class="form-group ">
                        <textarea
                            class="form-control" v-model="profileRequest.address" id="inputAddress" placeholder="လိပ်စာဖြည့်ပါ"></textarea>
                    </div>
                    <div class="form-group ">
                        <input type="password" v-model="profileRequest.password" 
                            class="form-control" id="inputPassword" placeholder="စကားဝှက်">
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <button type="submit" class="btn btn-primary mr-2" style="width: 100%;">မိမိ အချက်အလက်များကို ချိန်းမည်</button>
                        </div>
                    </div>
                </form>
                <div class="form-group row">
                    <div class="col-sm-12">
                        <router-link class="btn btn-info mt-2 text-center" to="/distri-order-history-list" style="height: 37px;line-height: 1.5;width: 100%;">
                            အောင်မြင်ပြီးသော အော်ဒါများကို ကြည့်မည်
                        </router-link>
                    </div>
                </div>
                <div class="form-group row" style="margin-top: 50%;">
                    <div class="col-sm-12">
                        <a href="#" class="btn btn-danger" style="width: 100%;"  v-b-modal.modal-1>ထွက်မည်</a>
                    </div>
                </div>
            </div>
            <b-modal id="modal-1" ref="delete-modal" title="ထွက်ရန်" hide-footer>
                <p class="my-4">ထွက်ရန်သေချာပါသလား။ </p>
                <b-button class="mt-3 btn-success" block @click="logout()">ထွက်မည်</b-button>
            </b-modal>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'location',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                profile: [],
                profileRequest: {
                    name: "",
                    phone: "",
                    address: "",
                    password: ""
                },
                validationData: {
                    name: ""
                }
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                profileAction: 'profileAction',
                profileChangeAction: 'profileChangeAction',
            }),
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/')
            },
            async userProfile() {
                this.isLoading = true;
                await this.profileAction().then(res => {
                    if (res.status == "success") {
                        this.profileRequest.name = res.data.name
                        this.profileRequest.phone = res.data.phone
                        this.profileRequest.address = res.data.address
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async onSubmit() {
                this.isLoading = true
                let validation = this.validationRule()
                if (validation == true) {
                    this.isLoading = false
                    return
                }
                let option = {
                    name: this.profileRequest.name,
                    password: this.profileRequest.password,
                    address: this.profileRequest.address
                }
                await this.profileChangeAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.profileRequest.name = res.data.name
                    this.profileRequest.phone = res.data.phone
                    this.profileRequest.address = res.data.address
                }).catch(err => this.isLoading = true)
            },
             validationRule() {
                let isValidation = []
                if (this.profileRequest.name == "") {
                    this.validationData.name = "အမည် ဖြည့်ပါ"
                    isValidation.push(true)
                } else {
                    this.validationData.name = ""
                    isValidation.push(false)
                }
                if (isValidation.includes(true)) {
                    return true
                } else {
                    return false
                }
            }
        },
        async mounted() {
            this.userProfile()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>